import AssassinImage from './Assassin.png';
import DrunkMerlinImage from './DrunkMerlin.png';
import LoyalServantImage from './LoyalServant.png';
import MerlinImage from './Merlin.png';
import MinionImage from './Minion.png';
import MordredImage from './Mordred.png';
import MorganaImage from './Morgana.png';
import OberonImage from './Oberon.png';
import PercivalImage from './Percival.png';
import UnknownImage from './Unknown.png';

export const Assassin = AssassinImage;
export const DrunkMerlin = DrunkMerlinImage;
export const LoyalServant = LoyalServantImage;
export const Merlin = MerlinImage;
export const Minion = MinionImage;
export const Mordred = MordredImage;
export const Morgana = MorganaImage;
export const Oberon = OberonImage;
export const Percival = PercivalImage;
export const Unknown = UnknownImage;
